import React from 'react';
import MuiTooltip from "../MuiComponents/MuiTooltip";
import InfoIcon from "@mui/icons-material/Info";

const Info = ({ tooltip = null, iconColor = 'var(--grey1)' }) => {
    return (
        <MuiTooltip
            title={tooltip}
            componentsProps={{
                tooltip: {
                    sx: {
                        maxHeight: 300, // Set maximum height for the tooltip
                        overflowY: 'hidden', // Enable vertical scrollbar if content exceeds maxHeight
                        padding: '0px',
                    },
                },
            }}
        >
            <InfoIcon
                style={{
                    height: 20,
                    color: iconColor,
                }}
            />
        </MuiTooltip>
    );
};

export default Info;
