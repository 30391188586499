import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from "@mui/material";
import Info from '../Info';

const MuiTabs = props => {
    const { tabs = [], value = 0, onChange, className, ...otherProps } = props;

    return (
        <Tabs
            className={className}
            variant="scrollable"
            scrollButtons="auto"
            value={value}
            onChange={onChange}
            {...otherProps}
        >
            {tabs?.map((item, index) => {
                const isObject = typeof item === 'object' && item !== null;
                const label = isObject ? item.label : item;
                const tooltip = isObject ? item.tooltip : '';

                return (
                    <Tab
                        key={index}
                        label={
                            <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                {label}
                                {tooltip && <Info iconColor={`${value === index ? 'bg-primary' : ''}`} tooltip={tooltip} />}
                            </span>
                        }
                        sx={{
                            textTransform: 'none',
                            color: theme => (value === index ? theme.palette.primary.main : theme.palette.text.secondary),
                            fontWeight: value === index ? 'bold' : 'normal',
                            '&.Mui-selected': {
                                color: theme => theme.palette.primary.main,
                            },
                        }}
                    />
                );
            })}
        </Tabs>
    );
};

MuiTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string, // For plain labels
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                tooltip: PropTypes.string, // Optional tooltip (used with Info component)
            }),
        ])
    ),
    value: PropTypes.number,
    onChange: PropTypes.func,
};

export default MuiTabs;
