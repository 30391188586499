import { useEffect } from "react";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import {
    ALL_BLOCKERS,
    ALL_FINDINGS, DRAFT_FINDINGS, LIST_CUSTOMER,
    OPEN_FINDING_COUNT,
    PENTEST_COUNT_BY_STAGE, PENTEST_STATUS, POINTS_CORNER,
    WELCOME_BANNER,
    YOUR_ASSETS,
    YOUR_PENTESTS,
    LIST_ASSIGNED_CUSTOMER,
    OVERALL_LANDSCAPE_AND_PENTEST, 
    ENTERPRISE_ASSETS, 
    BANNER_DETAILS, 
    BREAKDOWN_DETAILS, 
    PENTEST_ASSETS, 
    USER_MANAGEMENT, 
    ASSET_MANAGEMENT, 
    ASSETS, 
    ASSET_DEPARTMENTS, 
    PENTEST_ITERATION_HISTORY_DETAILS, 
    LIST_CUSTOMER_TEAM, 
    LIST_VULNERABILITY_ASSESSMENT,
    LIST_SCAN_HISTORY,
    CTEM_DASHBOARD,
    ENTERPRISE_RISK_SCORE,
    LIST_EASM,
    LIST_EASM_MONITORING,
} from "./utils/constants";
import axios from "axios";
import { paginationAttr } from "../actions/commonActions";
import queryString from "query-string";

export const usePentestCountByStage = (options = {}) => {
    const {
        company_id = null
    } = options
    return useQuery(PENTEST_COUNT_BY_STAGE, async () => {
        let res = await axios.get(`pentest/dashboard/pentest_count_by_stage${(company_id && `?company_id=${company_id}`) || ""}`)
        return res?.data?.pentestStageCount
    })
}
export const useOpenFindingsCount = (options = {}) => {
    const {
        company_id = null
    } = options
    return useQuery(OPEN_FINDING_COUNT, async () => {
        let res = await axios.get(`/dashboard/open_findings_count${(company_id && `?company_id=${company_id}`) || ""}`)
        return res?.data?.openFindingsCount
    })
}
export const useWelcomeBanner = (options = {}) => {
    const {
        company_id = null
    } = options
    return useQuery(WELCOME_BANNER, async () => {
        let res = await axios.get(`/dashboard/welcome_banner${company_id ? `?company_id=${company_id}` : ""}`)
        return res?.data?.welcomeBanner
    })
}

export const useYourCompany = (options = {}) => {
    const {
        company_id = null
    } = options
    return useQuery(WELCOME_BANNER, async () => {
        let res = await axios.get(`/dashboard/list_companies${company_id ? `?company_id=${company_id}` : ""}`)
        return res?.data;
    })
}

export const useYourPentestAssets = (options = {}) => {
    const {
        search = null,
        pageSize = 10,
        sortBy = [],
        enabled = true,
        asset_platform = null,
        stage=null,
        pentest_id = null,
        company_id = null,
        tester_id = null,
        testStage = null,
        displayRiskyAssets = null,
        skipTestStage = null,
        assetId = null,
    } = options
    return useInfiniteQuery([YOUR_ASSETS, {
        search,
        pageSize,
        sortBy,
        asset_platform,
        stage,
        pentest_id,
        company_id,
        tester_id,
        displayRiskyAssets,
        skipTestStage,
        assetId,
    }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`dashboard/list_my_assets${paginationAttr({
            page: pageParam,
            pageSize,
            search,
            sortBy,
            asset_platform,
            stage,
            pentest_id,
            company_id,
            tester_id,
            testStage,
            displayRiskyAssets,
            skipTestStage,
            assetId,
        })}`)
        return res.data
    }, {
        enabled,
        getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
        keepPreviousData: true
    })
}

export const useYourAssets = (options = {}) => {
    const {
        search = null,
        pageSize = 10,
        sortBy = [],
        enabled = true,
        asset_platform = null,
        stage=null,
        pentest_id = null,
        companyIds = null,
        assetCriticality = null,
        tester_id = null,
        testStage = null,
        displayRiskyAssets = null,
        skipTestStage = null,
        id = null,
        assetId = null,
    } = options
    return useInfiniteQuery([YOUR_ASSETS, {
        search,
        pageSize,
        sortBy,
        asset_platform,
        stage,
        pentest_id,
        assetCriticality,
        companyIds,
        tester_id,
        displayRiskyAssets,
        skipTestStage,
        id,
        assetId,
    }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`dashboard/assets${paginationAttr({
            page: pageParam,
            pageSize,
            search,
            sortBy,
            asset_platform,
            stage,
            pentest_id,
            assetCriticality,
            companyIds,
            tester_id,
            testStage,
            displayRiskyAssets,
            skipTestStage,
            id,
            assetId,
        })}`)
        return res.data
    }, {
        enabled,
        getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
        keepPreviousData: true
    })
}

export const useYourAssetList = (options = {}) => {
    const {
        search = null,
        pageSize = 10,
        sortBy = [],
        enabled = true,
        asset_platform = null,
        stage=null,
        pentest_id = null,
        companyIds = null,
        assetCriticality = null,
        tester_id = null,
        testStage = null,
        displayRiskyAssets = null,
        skipTestStage = null,
        id = null,
        assetId = null,
        platform = null,
        company_id = null
    } = options
    return useInfiniteQuery([YOUR_ASSETS, {
        search,
        pageSize,
        sortBy,
        asset_platform,
        stage,
        pentest_id,
        assetCriticality,
        companyIds,
        tester_id,
        displayRiskyAssets,
        skipTestStage,
        id,
        assetId,
        platform,
        company_id
    }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`assets${paginationAttr({
            page: pageParam,
            pageSize,
            search,
            sortBy,
            asset_platform,
            stage,
            pentest_id,
            assetCriticality,
            companyIds,
            tester_id,
            testStage,
            displayRiskyAssets,
            skipTestStage,
            id,
            assetId,
            platform,
            company_id
        })}`)
        return res.data
    }, {
        enabled,
        getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
        keepPreviousData: true
    })
}

export const useYourPendingAssetPrerequisites = (options = {}) => {
    const {
        search = null,
        pageSize = 10,
        sortBy = [],
        enabled = true,
        asset_platform = null,
        stage=null,
        pentest_id = null,
        tester_id = null,
        testStage = null,
        displayRiskyAssets = null
    } = options
    
    return useInfiniteQuery([YOUR_ASSETS, {
        search,
        pageSize,
        sortBy,
        asset_platform,
        stage,
        pentest_id,
        tester_id,
        displayRiskyAssets
    }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`dashboard/list_my_pending_asset_prerequisites${paginationAttr({
            page: pageParam,
            pageSize,
            search,
            sortBy,
            asset_platform,
            stage,
            pentest_id,
            tester_id,
            testStage,
            displayRiskyAssets
        })}`)
        return res.data
    }, {
        enabled,
        getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
        keepPreviousData: true
    })
}

export const useWelcomeBannerUserManagement = (options = {}) => {
    const {
        userType = null,
    } = options
    return useQuery([BANNER_DETAILS, { userType }], async () => {
        let res = await axios.get(`userManagement/listUsers${(userType && `?userType=${userType}`) || ""}`)
        return res?.data
    })

}

export const useWelcomeBannerAssetManagement = (options = {}) => {
    const {
        company_id = null,
    } = options
    return useQuery([BANNER_DETAILS, { company_id }], async () => {
        let res = await axios.get(`assetManagement/listAssets${(company_id && `?company_id=${company_id}`) || ""}`)
        return res?.data
    })

}

export const useUserManagement = (options = {}) => {
    const {
        search = null,
        userType = null,
        pageSize = 10,
        enabled = true,
        sortBy,
        company_id,
        status,
        role,
    } = options
    return useInfiniteQuery([USER_MANAGEMENT, {
        search,
        userType,
        pageSize,
        sortBy,
        company_id,
        status,
        role
    }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`userManagement/listUsers${paginationAttr({
            userType,
            pageSize,
            page: pageParam,
            search,
            sortBy,
            company_id,
            status,
            role
        })}`)
        return res.data
    }
        , {
            enabled,
            getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
            keepPreviousData: true
        }
    )
}

export const useAssetManagement = (options = {}) => {
    const {
        search = null,
        pageSize = 10,
        enabled = true,
        sortBy,
        company_id,
        platform,
        department,
        priority,
        stage
    } = options

    return useInfiniteQuery([ASSET_MANAGEMENT, {
        search,
        pageSize,
        sortBy,
        company_id,
        platform,
        department,
        priority,
        stage,
    }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`assetManagement/listAssets${paginationAttr({
            pageSize,
            page: pageParam,
            search,
            sortBy,
            company_id,
            platform,
            department,
            priority,
            stage
        })}`)
        return res.data
    }
        , {
            enabled,
            getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
            keepPreviousData: true
        }
    )
}

export const useEASM = (options = {}) => {
    const {
        search = null,
        pageSize = 10,
        enabled = true,
        sortBy,
        company_id,
        platform,
        tls,
        tech_stack,
        not_my_asset,
        department,
        priority,
        parent_id,
        stage,
        status,
        in_scope,
        grade,
        startDate,
        endDate
    } = options;
    return useInfiniteQuery([LIST_EASM, {
        search,
        pageSize,
        sortBy,
        company_id,
        platform,
        tls,
        tech_stack,
        not_my_asset,
        department,
        priority,
        parent_id,
        stage,
        status,
        in_scope,
        grade,
        startDate,
        endDate
    }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`easm/attack_surface${paginationAttr({
            pageSize,
            page: pageParam,
            search,
            sortBy,
            company_id,
            platform,
            not_my_asset,
            tls,
            tech_stack,
            department,
            priority,
            parent_id,
            stage,
            status,
            in_scope,
            grade,
            startDate,
            endDate
        })}`)
        return res.data
    }
        , {
            enabled,
            getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
            keepPreviousData: true
        }
    )
}

export const useEASMMonitoring = (options = {}) => {
    const {
        search = null,
        pageSize = 10,
        enabled = true,
        sortBy,
        company_id,
        priority,
        stage,
        status,
        startDate,
        endDate,
        monitoring_enabled
    } = options;


    return useInfiniteQuery([LIST_EASM_MONITORING, {
        search,
        pageSize,
        sortBy,
        company_id,
        priority,
        stage,
        status,
        startDate,
        endDate,
        monitoring_enabled
    }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`easm/monitoring${paginationAttr({
            pageSize,
            page: pageParam,
            search,
            sortBy,
            company_id,
            priority,
            stage,
            status,
            startDate,
            endDate,
            monitoring_enabled
        })}`)
        return res.data
    }
        , {
            enabled,
            getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
            keepPreviousData: true
        }
    )
}

export const usePentestAssets = (options = {}) => {
    const {
        pentestIds = [],
    } = options
    return useQuery([PENTEST_ASSETS, {
        pentestIds
    }], async () => {
        if (pentestIds && pentestIds?.length > 0) {
            let ids = queryString.stringify({ pentest_id: pentestIds }, { arrayFormat: 'bracket' });
            let res = await axios.get(`/pentest/asset/list_pentest_assets${ids ? `?${ids}` : ""}`)
            return res.data?.result;
        }
    })
}

export const useRiskiestAssets = (options = {}) => {
    const {
        search = null,
        pageSize = 10,
        sortBy = [],
        enabled = true,
        platform = null,
        company_id = null
    } = options
    return useInfiniteQuery([YOUR_ASSETS, {
        search,
        pageSize,
        sortBy,
        platform,
        company_id
    }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`dashboard/enterprise/riskiest_assets${paginationAttr({
            page: pageParam,
            pageSize,
            search,
            sortBy,
            platform,
            company_id
        })}`)
        return res.data
    }, {
        enabled,
        getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
        keepPreviousData: true
    })
}
export const useListCustomer = (options = {}) => {
    const {
        search = null,
        pageSize = 10,
        sortBy = [],
        enabled = true,
        easm_check = false,
        genpt_check = false
    } = options;
    return useInfiniteQuery([LIST_CUSTOMER, { search, pageSize, sortBy }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`dashboard/list_my_customers${paginationAttr({
            page: pageParam,
            pageSize,
            search,
            easm_check,
            genpt_check,
            sortBy
        })}`)
        return res.data
    }, {
        getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
        enabled,
        keepPreviousData: true
    })
}

export const useListVulnerabilityAssessment = (options = {}) => {
    const {
        companyId,
        search = null,
        status = [],
        company = [],
        assetCriticality = [],
        startDate = null,
        endDate = null,
        pageSize = 10,
        sortBy = [],
        enabled = true
    } = options
    return useInfiniteQuery([LIST_VULNERABILITY_ASSESSMENT , 
        { 
            companyId, 
            search, 
            status, 
            company, 
            pageSize, 
            sortBy, 
            assetCriticality, 
            startDate,
            endDate, 
        }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`/pentest/asset/scan-summary${paginationAttr({
            companyId,
            page: pageParam,
            pageSize,
            search,
            status,
            company,
            assetCriticality,
            startDate,
            endDate,
            sortBy
        })}`)
        return res.data
    }, {
        getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
        enabled,
        keepPreviousData: true
    })
}

export const useScanHistory = (options = {}) => {
    const {
        assetId,
        search = null,
        pageSize = 10,
        sortBy = [],
        enabled = true
    } = options
    return useInfiniteQuery([LIST_SCAN_HISTORY , { search, assetId, pageSize, sortBy }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`/pentest/asset/scan-history${paginationAttr({
            assetId,
            page: pageParam,
            pageSize,
            search,
            sortBy
        })}`)
        return res.data
    }, {
        // getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
        enabled,
        keepPreviousData: true
    })
}

export const useListCustomerTeam = (options = {}) => {
    const {
        search = null,
        pageSize = 10,
        sortBy = [],
        enabled = true,
        pentest_id,
        pentest_asset_id,
        company_id
    } = options
    return useInfiniteQuery([LIST_ASSIGNED_CUSTOMER, { search, pageSize, sortBy, pentest_id, pentest_asset_id, company_id }], async ({ pageParam = 1 }) => {
        // if(pentest_asset_id && pentest_id){
            let res = await axios.get(`dashboard/list_my_customer_teams${paginationAttr({
                page: pageParam,
                pageSize,
                search,
                sortBy,
                pentest_id,
                pentest_asset_id,
                company_id
            })}`);
            //console.log("res.data", res.data)
            return res.data
        // }else {
        //     console.log("hrere")
            //return []
        // }
    }, {
        getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
        enabled,
        keepPreviousData: true
    })
}

export const useYourPentests = (options = {}) => {
    const {
        search = null,
        pageSize = 10,
        pentestType,
        stage = null,
        sortBy = [],
        company_id = null,
        tester_id = null,
        filterStatus = null,
        openFindingRangeStart,
        openFindingRangeEnd,
    } = options
    return useInfiniteQuery([YOUR_PENTESTS, {
        search,
        pageSize,
        pentestType,
        sortBy,
        stage,
        company_id,
        tester_id,
        filterStatus,
        openFindingRangeStart,
        openFindingRangeEnd,
    }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`dashboard/list_my_pentests${paginationAttr({
            page: pageParam,
            pageSize,
            search,
            pentestType,
            sortBy,
            stage,
            company_id,
            tester_id,
            filterStatus,
            openFindingRangeStart,
            openFindingRangeEnd,
        })}`)
        return res.data
    }, {
        keepPreviousData: true,
        getNextPageParam: lastPage => lastPage?.nextPage ?? undefined
    })
}

export const useAllFindings = (options = {}) => {
    const {
        search = null,
        pageSize = 10,
        sortBy = [],
        enabled = true,
        status = null,
        severity = null,
        pentest_id = null,
        pentest_asset_id = null,
        company_id = null,
        dateRangeStart = null,
        dateRangeEnd = null,
        customers = null,
    } = options    
    return useInfiniteQuery([ALL_FINDINGS, {
        search,
        pageSize,
        sortBy,
        status,
        severity,
        pentest_id,
        pentest_asset_id,
        company_id,
        dateRangeStart,
        dateRangeEnd,
        customers
    }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`dashboard/list_my_asset_findings${paginationAttr({
            page: pageParam, pageSize, search, sortBy, status, severity, pentest_id, pentest_asset_id, company_id, dateRangeStart, dateRangeEnd, customers
        })}`)
        return res.data
    }, {
        getNextPageParam: lastPage => lastPage?.nextPage ?? undefined,
        enabled,
        keepPreviousData: true
    })
}

export const useAllBlockers = (options = {}) => {
    const {
        search = null,
        pageSize = 10,
        sortBy = [],
        company_id = null,
        pentest_asset_id = null,
        pentest_id = null,
        platform = null,
        status = null,
        dateRangeStart = null,
        dateRangeEnd = null,
    } = options
    return useInfiniteQuery([ALL_BLOCKERS, {
        search,
        pageSize,
        sortBy,
        company_id,
        pentest_asset_id,
        pentest_id,
        platform,
        status,
        dateRangeStart,
        dateRangeEnd,
    }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`dashboard/list_my_asset_blockers${paginationAttr({
            page: pageParam, pageSize, search, sortBy, company_id, pentest_asset_id, pentest_id, platform, status, dateRangeStart, dateRangeEnd
        })
            }`)
        return res.data
    }, {
        keepPreviousData: true,
        getNextPageParam: lastPage => lastPage?.nextPage ?? undefined
    })
}

export const useDraftFindings = (options = {}) => {
    const {
        search = null,
        pageSize = 10,
        sortBy = []
    } = options
    return useInfiniteQuery([DRAFT_FINDINGS, { search, pageSize, sortBy }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`dashboard/list_my_drafted_findings${paginationAttr({
            page: pageParam,
            pageSize,
            search,
            sortBy
        })}`)
        return res.data
    }, {
        getNextPageParam: lastPage => lastPage?.nextPage ?? undefined,
        keepPreviousData: true
    })
}

export const useAllPentestStatus = (options = {}) => {
    const {
        pentest_asset_id = null,
        pentest_id = null,
        page = 1,
        pageSize = 10
    } = options
    return useQuery([PENTEST_STATUS, { pentest_asset_id, pentest_id, page, pageSize }], async () => {
        let res = await axios.get(`dashboard/list_my_pentest_status${paginationAttr({
            page,
            pageSize,
            pentest_asset_id,
            pentest_id
        })}`)
        return res.data
    })
}
export const useGetUpdateById = (status_id) => {
    return useQuery([PENTEST_STATUS, status_id], async () => {
        let res = await axios.get(`/pentest/latest_status/${status_id}`)
        return res.data
    })
}

export const useEditLatestUpdates = (options = {}) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.post(`/pentest/latest_status/create_latest_status/`, data)
        return res.data
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ASSETS)
            queryClient.invalidateQueries(PENTEST_STATUS)
            queryClient.invalidateQueries(ALL_BLOCKERS)
            queryClient.invalidateQueries(BANNER_DETAILS)
        }
    })
}



export const usePointsCorner = () => {
    return useQuery(POINTS_CORNER, async () => {
        let res = await axios.get(`dashboard/points_corner`)
        return res.data
    })
}

export const useOverallLandscapeAndPentest = () => {
    return useQuery(OVERALL_LANDSCAPE_AND_PENTEST, async () => {
        let res = await axios.get(`dashboard/overall_landscape_and_pentest`)
        return res.data
    })
}

export const useEnterpriseAssets = (options = {}) => {
    const {
        company_id = null
    } = options
    return useQuery([ENTERPRISE_ASSETS, company_id], async () => {
        let res = await axios.get(`dashboard/enterprise/assets${(company_id && `?company_id=${company_id}`) || ""}`)
        return res.data
    })
}

export const useBannerDetails = (options = {}) => {
    const {
        company_id = null,
        type = null,
        pentest_id = null,
        pentest_asset_id = null,
        platform_category = null
    } = options
    return useQuery([BANNER_DETAILS, { type, company_id, pentest_id, pentest_asset_id, platform_category}], async () => {
        let res = await axios.get(`/welcome_banner${paginationAttr({
            company_id,
            type,
            pentest_id,
            pentest_asset_id,
            platform_category
        })}`)
        return res?.data?.result
    })
}

export const useBreakdownChartData = (options = {}) => {
    const {
        pentest_asset_id = null,
        pentest_id = null
    } = options
    return useQuery(BREAKDOWN_DETAILS, async () => {
        let res = await axios.get(`/dashboard/charts/breakdown_count${paginationAttr({
            pentest_asset_id,
            pentest_id
        })}`)
        return res.data
    })
}

export const assetDepartment = (options = {}) => {
    const {
        company_id = null,
    } = options;
    return new Promise((resolve, reject) => {
        axios.get(`/assetManagement/listDepartments${paginationAttr({
            company_id
        })}`)
        .then(res => {
            resolve(res.data);
        })
        .catch(error => {
            reject(error)
        })
    });
}

export const usePentestIterationHistoryData = (options = {}) => {
    const {
        pentest_id = null,
        sortBy = [],
    } = options
    return useInfiniteQuery([PENTEST_ITERATION_HISTORY_DETAILS, { pentest_id, sortBy }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`/dashboard/list_my_pentest_iteration_history/${pentest_id}${paginationAttr({
            page: pageParam,
            sortBy
        })}`)
        return res.data
    }, {
        getNextPageParam: lastPage => lastPage?.nextPage ?? undefined,
        keepPreviousData: true
    })
}

export const useCTEMDashboard = (companyId) => {
    return useQuery(CTEM_DASHBOARD, async () => {
        let res = await axios.get(`/dashboard/ctem?companyId=${companyId}`)
        return res.data.data;
    })
}

export const useRefreshCTEMDashboard = (companyId, options = {}) => {
    const queryClient = useQueryClient();
    return useMutation(async (data) => {
        let res = await axios.post(`/ctem/reload/${companyId}`, data)
        return res.data
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(CTEM_DASHBOARD)
        },
        ...options
    })
}
