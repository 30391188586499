import React from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "../../components/Admin/Layout";
import {
    AllAssets,
    AllAssetsFilter, AllBlockers,
    AllFindings,
    AllFindingsFilter, AllHighRiskAssets, AllPentests, AllPentestsFilter, AssetDashboard, AssetDescAndUserStories, Chat,
    Dashboard, EditAssetScope, EnterpriseDashboard,
    FindingDashboard, PentestDashboard, PentestLandscape, PreRequisites, QualysFindingDashboard, ScanQualysFindingDashboard, VADashboard, GenPT, GenPTTest, GenPTTestFindings, GenPTFindings,
    CTEMDashboard,
    AllEASM,
    DomainDashboard,
    EASMMonitoring,
    EASMOverallDashboard,
    CustomerSelection,
    SubdomainDashboard
} from "../pages/PageListAsync";
import withPermissions from "../../hoc/withPermissions";
import { SIEMBA_CUSTOMER, SIEMBA_CUSTOMER_ENGINEER } from "../../utils/roles";
import LayoutProvider from "../../Providers/LayoutProvider";
import VulnerabilityAssessment from '../pages/Admin/VulnerabilityAssessment';
import { SIEMBAGPT_ENABLED } from '../../utils/constants';
import { useSelector } from 'react-redux';
import AllVAVulnerabilities from '../pages/Admin/VulnerabilityAssessment/AllVAVulnerabilities';
import { useValidateLogin } from '../../hooks/auth';

const MyDashboardRoutes = () => {
    const { integrationsEnabled } = useSelector(state => state.auth);
    const is_genpt_enabled = SIEMBAGPT_ENABLED === 'true' && integrationsEnabled?.is_genpt_enabled;

    const { data } = useValidateLogin();
    let defaultPath = '/app/ctem_dashboard';
    // if (data?.user?.role === "customer_leader") {
    //     defaultPath = '/app/ctem_dashboard'
    // }

    return (
        <LayoutProvider value={{
            dashboard: "MY_DASHBOARD",
            mainUrl: "/app"
        }}>
            <Layout>
                <Routes>
                    <Route path={"/"} element={<Navigate to={defaultPath} />} />
                    <Route path={"/dashboard"} element={<Dashboard dashboard={"MY_DASHBOARD"} />} />
                    {/*blockers*/}
                    <Route path={"/pentests/all_blockers"} element={<AllBlockers />} />
                    <Route path={"/dashboard/:company_id/all_blockers"} element={<AllBlockers />} />

                    {/* CTEM dashboard */}
                    <Route path={"ctem_dashboard"} element={<CTEMDashboard />} />

                     {/* EASM */}
                    <Route path={"/easm/insights"} element={<EASMOverallDashboard />} />
                    <Route path={"/easm/attack_surface"} element={<AllEASM />} />
                    <Route path={'/easm/monitoring'} element={<EASMMonitoring />} />
                    <Route path={"/easm/monitoring/:id/:tabElement"} element={<DomainDashboard />} />
                    <Route path={"/easm/attack_surface/subdomain/:id"} element={<SubdomainDashboard />} />

                    {/*findings route*/}
                    <Route path={"/pentests/all_findings"} element={<AllFindings />} />
                    <Route path={"/pentests/all_findings/:id/dashboard"} element={<FindingDashboard />} />
                    <Route path={"/genpt/tests/:sessionId/findings/:id/dashboard"} element={<FindingDashboard from={'GENPT'}/>} /> 
                    <Route path={"/genpt/all_findings/:id/dashboard"} element={<FindingDashboard from={'GENPT'}/>} /> 
                    <Route path={"/pentests/all_findings/:filter"} element={<AllFindingsFilter />} />
                    <Route path={"/dashboard/:company_id/all_findings"} element={<AllFindings />} />
                    <Route path={"/dashboard/:company_id/all_findings/:filter"} element={<AllFindingsFilter />} />
                    <Route path={"/va/all_findings/:findingId/dashboard"} element={<QualysFindingDashboard />} />
                    <Route path={"/all_va/finding/:findingId/dashboard"} element={<ScanQualysFindingDashboard />} />

                    {/*assets routes*/}
                    <Route path={"/all_assets"} element={<AllAssets />} />
                    <Route path={"/all_assets/:filter"} element={<AllAssetsFilter />} />
                    <Route path={"/all_assets/riskiest_assets"} element={<AllHighRiskAssets />} />
                    <Route path={"/all_assets/riskiest_assets/:pentest_id"} element={<AllHighRiskAssets />} />
                    <Route path={"/all_assets/:id/dashboard"} element={<AssetDashboard />} />
                    <Route path={"/all_assets/:id/edit_scope"} element={<EditAssetScope />} />
                    <Route path={"/all_assets/:id/asset_desc_and_user_stories"} element={<AssetDescAndUserStories />} />
                    <Route path={"/all_assets/:asset_id/all_findings"} element={<AllFindings />} />
                    <Route path={"/all_assets/:asset_id/all_findings/:filter"} element={<AllFindingsFilter />} />
                    <Route path={"/all_assets/:asset_id/pre_requisites"} element={<PreRequisites />} />
                    {/*pentests routes*/}
                    <Route path={"/all_pentests"} element={<AllPentests />} />
                    <Route path={"/pentests/:filter"} element={<AllPentestsFilter />} />
                    <Route path={"/all_pentests/:id/dashboard"} element={<PentestDashboard />} />
                    <Route path={"/all_pentests/:pentest_id/all_findings"} element={<AllFindings />} />
                    <Route path={"/all_pentests/:pentest_id/pentest_landscape"} element={<PentestLandscape />} />
                    <Route path={"/all_pentests/:pentest_id/all_findings/:filter"} element={<AllFindingsFilter />} />
                    <Route path={"/chat"} element={<Chat />} />
                    <Route path={"/chat/:conversation_id"} element={<Chat />} />
                    <Route path={"/pentests/insights"} element={<EnterpriseDashboard />} />
                    {/* Qualys Vuln assessment routes */}
                    <Route path={"all_va"} element={<VulnerabilityAssessment />} />
                    <Route path={"va/all_findings"} element={<AllVAVulnerabilities />} />
                    <Route path={"/all_va/:assetId/dashboard"} element={<VADashboard />} />
                    <Route path={"/genpt/tests"} element={<GenPT/>}/>
                    <Route path={"/genpt/tests/start"} element={<GenPTTest/>}/>
                    <Route path={"/genpt/tests/:session_id"} element={<GenPTTest/>}/>
                    <Route path={"/genpt/tests/:session_id/findings"} element={<GenPTTestFindings/>}/>
                    <Route path={"/genpt/all_findings"} element={<GenPTFindings/>}/>
                </Routes>
            </Layout>
        </LayoutProvider>
    );
};

export default withPermissions(MyDashboardRoutes, { roles: [SIEMBA_CUSTOMER, SIEMBA_CUSTOMER_ENGINEER] });
